import React from "react";
import { Link as GatsbyLink } from "gatsby";

export default function Link({ url, id, children, standout }) {
  let classes;
  if (standout) {
    classes =
      "bg-blue-900 text-white hover:border-blue hover:bg-blue-500 hover:text-black-500 px-3 py-2";
  } else {
    classes = "md:text-black-500 hover:bg-blue-900 hover:text-white px-3 py-2";
  }
  return (
    <GatsbyLink
      key={id}
      className={classes}
      style={{ textDecoration: "none" }}
      to={url}
    >
      {children}
    </GatsbyLink>
  );
}
