import React from "react";
import Link from "../components/Link";
import { Layout } from "../components/Layout";
import { Header } from "../components/Header";
import ProductElement from "../components/ProductElement";

export default function Index({ pageContext: { data, all } }) {
  const products = all ? data : data.slice(0, 8);
  return (
    <>
      <Layout>
        <Header />
        <ul className="flex flex-wrap justify-center max-w-screen-xl m-auto">
          {products.map((product) => (
            <ProductElement key={product.title} product={product} />
          ))}
        </ul>
        {all ? null : (
          <div className="text-center my-5">
            <Link id="all" url={"/all-art"} standout={true}>
              See all products
            </Link>
          </div>
        )}
      </Layout>
    </>
  );
}
